import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Check from '../components/check'
import Line from '../components/line'
import IconContainer from '../components/icon-container'
import './product-videos.css'

const ProductVideos = (props) => {
  return (
    <div className="product-videos-container">
      <Helmet>
        <title>ProductVideos - PersonaAI</title>
        <meta property="og:title" content="ProductVideos - PersonaAI" />
      </Helmet>
      <section className="product-videos-note">
        <h2 className="product-videos-caption">
          The most comprehensive lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna.
        </h2>
      </section>
      <section className="product-videos-statistics">
        <div className="product-videos-content">
          <div className="product-videos-stat">
            <h3 className="product-videos-header">$1.69M</h3>
            <span className="product-videos-caption01">
              Consectetur adipiscing
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </div>
          <div className="product-videos-stat1">
            <h3 className="product-videos-header01">Multiverse</h3>
            <span className="product-videos-caption02">Unde omnis iste</span>
          </div>
          <div className="product-videos-stat2">
            <h3 className="product-videos-header02">500K</h3>
            <span className="product-videos-caption03">
              Accusantium doloremque
            </span>
          </div>
        </div>
      </section>
      <section className="product-videos-slider">
        <div className="product-videos-header03">
          <h2 className="product-videos-heading">
            PersonaAI&apos;s Early Prototype Product Videos
          </h2>
        </div>
        <div className="product-videos-selector">
          <p className="product-videos-caption04">
            Early prototype of PersonaAI was codenamed &quot;Mirage&quot; and is
            released as an adult-oriented game, a strategic move designed to
            accelerate fund acquisition towards development of the PersonaAI
            platform.
          </p>
        </div>
        <div className="product-videos-div">
          <Script
            html={`<div class="video">
				<h3>Mirage - Launch Trailer:  (password is mirage31337)</h3>		
				<iframe src="https://player.vimeo.com/video/438821129" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>				
			</div>

			<div class="video">
				<h3>Review of Mirage by Rob Cram:</h3>
				<iframe width="560" height="315" src="https://www.youtube.com/embed/6VIPdUgavPs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>

			<div class="video">
				<h3>Animations:</h3>
				<iframe src="https://player.vimeo.com/video/439196364" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>

			<div class="video">
				<h3>Posing:</h3>
				<iframe src="https://player.vimeo.com/video/437457708" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>

			<div class="video">
				<h3>First girl:</h3>
				<iframe src="https://player.vimeo.com/video/437473241" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>

			<div class="video">
				<h3>Second girl:</h3>
				<iframe src="https://player.vimeo.com/video/437472836" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>			
			</div>

			<div class="video">
				<h3>Third girl:</h3>
				<iframe src="https://player.vimeo.com/video/437472432" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>
			
			<div class="video">
				<h3>Morphs:</h3>
				<iframe src="https://player.vimeo.com/video/437470830" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>		`}
          ></Script>
        </div>
      </section>
      <div className="product-videos-container01"></div>
      <div className="product-videos-gallery">
        <h1 className="product-videos-text">
          <span>
            Realtime
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="product-videos-text02">
            Rendering
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </h1>
      </div>
      <div className="product-videos-gallery1">
        <div className="product-videos-container02">
          <div className="product-videos-container03">
            <h1 className="product-videos-heading01">Rendering Sample #1</h1>
            <span className="product-videos-text03">
              Lorem ipsum dolor sit amet
            </span>
          </div>
        </div>
        <div className="product-videos-container04">
          <div className="product-videos-container05">
            <h1 className="product-videos-text04">High quality rendering #2</h1>
            <span className="product-videos-text05">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
        <div className="product-videos-container06">
          <div className="product-videos-container07">
            <h1 className="product-videos-text07">Project Title</h1>
            <span className="product-videos-text08">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
        <div className="product-videos-container08">
          <div className="product-videos-container09">
            <h1 className="product-videos-text10">Project Title</h1>
            <span className="product-videos-text11">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
        <div className="product-videos-container10">
          <div className="product-videos-container11">
            <h1 className="product-videos-text13">Project Title</h1>
            <span className="product-videos-text14">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
        <div className="product-videos-container12">
          <div className="product-videos-container13">
            <h1 className="product-videos-text16">Project Title</h1>
            <span className="product-videos-text17">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
      </div>
      <section className="product-videos-get-started">
        <div className="product-videos-header-container">
          <div className="product-videos-header04">
            <h2 className="product-videos-heading02">
              Innovation and Immersion at Customers Fingertips
            </h2>
            <p className="product-videos-caption05">
              <span className="product-videos-text19">
                Our platform allows users to create, share, and interact with
                lifelike 3D characters. The highly customizable characters and
                environments are perfect for user-generated content buying and
                selling through a marketplace.
              </span>
              <br className="product-videos-text20"></br>
              <br className="product-videos-text21"></br>
              <span className="product-videos-text22">
                Additionally, PersonaAI integrates with AI LLM models to bring
                characters to life, making them more interactive, dynamic, and
                alive than ever before. We make every interaction feel real,
                personal, and vibrant.
              </span>
            </p>
          </div>
          <div className="product-videos-button">
            <button className="button">
              <span>Get started today</span>
            </button>
          </div>
        </div>
      </section>
      <section className="product-videos-objectives">
        <div className="product-videos-content01">
          <span className="product-videos-text24">Objectives</span>
          <div className="product-videos-objectives-list">
            <div className="objective">
              <h3 className="product-videos-text25">Growth</h3>
              <p className="product-videos-text26">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="objective product-videos-objective1">
              <h3 className="product-videos-text27">Develop</h3>
              <p className="product-videos-text28">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="objective product-videos-objective2">
              <h3 className="product-videos-text29">Optimize</h3>
              <p className="product-videos-text30">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="product-videos-growth">
        <div className="product-videos-content02">
          <div className="product-videos-header05">
            <div className="product-videos-header06">
              <h2 className="product-videos-heading03">
                <span>The Future Is </span>
                <span className="product-videos-text32">AI/AR/VR</span>
                <br></br>
              </h2>
            </div>
            <p className="product-videos-caption06">
              With opportunities for release on popular platforms like Steam,
              Epic Games Store, Oculus Store, and Apple&apos;s Reality Pro App
              Store, we&apos;re primed for expansion. Additional growth avenues
              include adapting our product for online platforms and
              marketplaces, licensing our physics engine to other companies, and
              further developing our AI chatbots to stay at the forefront of
              AI-powered interaction.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
          </div>
        </div>
        <div className="product-videos-images">
          <div className="product-videos-square"></div>
          <img
            alt="image"
            src="https://aipersona.info/image854.jpg"
            className="product-videos-image"
          />
        </div>
      </section>
      <section className="product-videos-experience">
        <div className="product-videos-images1">
          <div className="product-videos-square1"></div>
          <img
            alt="image"
            src="/experience-1400w.png"
            className="product-videos-image1"
          />
        </div>
        <div className="product-videos-content03">
          <div className="product-videos-header07">
            <div className="product-videos-header-container1">
              <div className="product-videos-header08">
                <h2 className="product-videos-heading04">
                  <span>A worlds class </span>
                  <span className="product-videos-text35">experience</span>
                </h2>
              </div>
              <p className="product-videos-caption07">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
                eiusmod.
              </p>
            </div>
            <div className="product-videos-checkmarks">
              <div className="product-videos-check">
                <div className="product-videos-mark">
                  <svg viewBox="0 0 1024 1024" className="product-videos-icon">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="product-videos-text36">
                  Duis aute irure dolor in reprehenderit
                </span>
              </div>
              <div className="product-videos-check1">
                <div className="product-videos-mark1">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="product-videos-icon02"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="product-videos-text37">
                  Lorem ipsum dolor sit amet
                </span>
              </div>
              <div className="product-videos-check2">
                <div className="product-videos-mark2">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="product-videos-icon04"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="product-videos-text38">
                  Voluptate velit esse cillum dolore eu fugiat nulla
                </span>
              </div>
              <div className="product-videos-check3">
                <div className="product-videos-mark3">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="product-videos-icon06"
                  >
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="product-videos-text39">
                  Excepteur sint occaecat cupidatat non proident sunt in culpa
                </span>
              </div>
            </div>
          </div>
          <div className="product-videos-testimonial">
            <div className="product-videos-content04">
              <span className="product-videos-text40">Testimonial</span>
              <p className="product-videos-text41">
                “Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.”
              </p>
            </div>
            <div className="product-videos-information">
              <div className="product-videos-author">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY3MTA1NjMzMw&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="product-videos-icon08"
                />
                <span className="product-videos-name">Cory Smith</span>
              </div>
              <img
                alt="image"
                src="/logoipsum-2.svg"
                className="product-videos-from"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="product-videos-create">
        <div className="product-videos-content05">
          <div className="product-videos-header09">
            <h2 className="product-videos-heading05">
              <span>
                PersonaAI in
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="product-videos-text43">Action</span>
              <br></br>
            </h2>
          </div>
          <div className="product-videos-container14">
            <span className="product-videos-text45">
              <br className="product-videos-text46"></br>
              <br></br>
            </span>
            <span className="product-videos-text48">
              Get a taste of the PersonaAI experience through our early demo
              prototype, Mirage, available on Steam, or watch more of our
              product videos on our media page.
            </span>
          </div>
          <div>
            <Script
              html={`<iframe src="https://store.steampowered.com/widget/1245780/" frameborder="0" width="646" height="190"></iframe>`}
            ></Script>
          </div>
          <button className="product-videos-button2 button">
            <a
              href="https://morganavr.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              Watch Product Videos
            </a>
          </button>
        </div>
      </section>
      <section className="product-videos-comparision">
        <div className="product-videos-header-container2">
          <div className="product-videos-header10">
            <h2 className="product-videos-heading06">
              <span>Environment c</span>
              <span className="product-videos-text50">omparision</span>
              <br></br>
            </h2>
          </div>
          <p className="product-videos-caption08">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
            eiusmod.
          </p>
        </div>
        <div className="product-videos-table">
          <div className="product-videos-row">
            <div className="product-videos-headers">
              <span className="product-videos-text52">Placeholder</span>
            </div>
            <div className="product-videos-plans">
              <div className="product-videos-row01">
                <span className="product-videos-text53">TOGHTR</span>
                <span className="product-videos-text54">AGENCY</span>
                <span className="product-videos-text55">FRELLANCE</span>
              </div>
            </div>
          </div>
          <div className="product-videos-row02">
            <div className="product-videos-headers1">
              <span className="product-videos-text56">
                Duis aute irure dolor in reprehenderit
              </span>
            </div>
            <div className="product-videos-plans1">
              <div className="product-videos-row03">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="product-videos-row04">
            <div className="product-videos-headers2">
              <span className="product-videos-text57">
                Lorem ipsum dolor sit amet
              </span>
            </div>
            <div className="product-videos-plans2">
              <div className="product-videos-row05">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="product-videos-row06">
            <div className="product-videos-headers3">
              <span className="product-videos-text58">
                Duis aute irure dolor in reprehenderit
              </span>
            </div>
            <div className="product-videos-plans3">
              <div className="product-videos-row07">
                <div className="value">
                  <span className="product-videos-text59">Custom</span>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="product-videos-row08">
            <div className="product-videos-headers4">
              <span className="product-videos-text60">
                Duis aute irure dolor in reprehenderit
              </span>
            </div>
            <div className="product-videos-plans4">
              <div className="product-videos-row09">
                <div className="value">
                  <span className="product-videos-text61">Unlimited</span>
                </div>
                <div className="value">
                  <span className="product-videos-text62">Limited</span>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="product-videos-row10">
            <div className="product-videos-headers5">
              <span className="product-videos-text63">
                Lorem ipsum dolor sit amet
              </span>
            </div>
            <div className="product-videos-plans5">
              <div className="product-videos-row11">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
                <div className="value">
                  <span className="product-videos-text64">Limited</span>
                </div>
              </div>
            </div>
          </div>
          <div className="product-videos-row12">
            <div className="product-videos-headers6">
              <span className="product-videos-text65">
                Lorem ipsum dolor sit amet
              </span>
            </div>
            <div className="product-videos-plans6">
              <div className="product-videos-row13">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="product-videos-row14">
            <div className="product-videos-headers7">
              <span className="product-videos-text66">
                Lorem ipsum dolor sit amet
              </span>
            </div>
            <div className="product-videos-plans7">
              <div className="product-videos-row15">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <span className="product-videos-text67">Limited</span>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="product-videos-data">
        <div className="product-videos-header-container3">
          <div className="product-videos-header11">
            <div className="product-videos-row16">
              <h2 className="product-videos-heading07">
                <span>
                  Understand your data to manage all in one
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="product-videos-text69">platform</span>
                <br></br>
              </h2>
            </div>
          </div>
          <p className="product-videos-caption09">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </p>
        </div>
        <div className="product-videos-content06">
          <div className="product-videos-image2">
            <img
              alt="image"
              src="/data-1400w.png"
              className="product-videos-image3"
            />
          </div>
          <div className="product-videos-accordion">
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="product-videos-header12">Management</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name4"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="product-videos-header13">Integrations</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name5"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="product-videos-header14">Customer Solutions</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name6"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="product-videos-header15">Recruiting</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name7"></IconContainer>
            </div>
            <div>
              <Script
                html={`<script>
 /*
  Accordion - Code Embed
  */
  const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
  const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
  const accordionIconsClosed = document.querySelectorAll('[data-role="accordion-icon-closed"]'); // All accordion closed icons
  const accordionIconsOpen = document.querySelectorAll('[data-role="accordion-icon-open"]'); // All accordion open icons

  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = "none"; //Hides all accordion contents
  });

  accordionIconsClosed.forEach((icon) => {
    icon.style.display = "flex"
  })

  accordionIconsOpen.forEach((icon) => {
    icon.style.display = "none"
  })

  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener("click", () => {
          if (accordionContents[index].style.display === "flex") {
              // If the accordion is already open, close it
              accordionContents[index].style.display = "none";
              accordionIconsClosed[index].style.display = "flex";
              accordionIconsOpen[index].style.display = "none"
          } else {
              // If the accordion is closed, open it
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none"; //Hides all accordion contents
              });

              accordionIconsClosed.forEach((accordionIcon) => {
                  accordionIcon.style.display = "flex"; // Resets all icon transforms to 0deg (default)
              });

              accordionIconsOpen.forEach((accordionIcon) => {
                accordionIcon.style.display = "none";
              })
              
              accordionContents[index].style.display = "flex"; // Shows accordion content
              accordionIconsClosed[index].style.display = "none"; // Rotates accordion icon 180deg
              accordionIconsOpen[index].style.display = "flex";
          }
      });
  });
</script>
`}
              ></Script>
            </div>
          </div>
        </div>
      </section>
      <section className="product-videos-customer">
        <div className="product-videos-header16">
          <h2 className="product-videos-heading08">
            <span>
              Customer validation is an essential phase of the
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="product-videos-text72">
              product development process
            </span>
            <br></br>
          </h2>
        </div>
        <div className="product-videos-quotes">
          <div className="product-videos-quote">
            <p className="product-videos-quote1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
            <div className="product-videos-author1">
              <span className="product-videos-name1">Joanna Smith</span>
              <span className="product-videos-location">Briville</span>
            </div>
          </div>
          <div className="product-videos-quote2">
            <p className="product-videos-quote3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
            <div className="product-videos-author2">
              <span className="product-videos-name2">Joanna Smith</span>
              <span className="product-videos-location1">Briville</span>
            </div>
          </div>
          <div className="product-videos-quote4">
            <p className="product-videos-quote5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
            <div className="product-videos-author3">
              <span className="product-videos-name3">Joanna Smith</span>
              <span className="product-videos-location2">Briville</span>
            </div>
          </div>
          <div className="product-videos-quote6">
            <p className="product-videos-quote7">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
            <div className="product-videos-author4">
              <span className="product-videos-name4">Joanna Smith</span>
              <span className="product-videos-location3">Briville</span>
            </div>
          </div>
        </div>
        <div className="product-videos-controls">
          <button className="product-videos-previous button">
            <svg viewBox="0 0 1024 1024" className="product-videos-icon09">
              <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
            </svg>
          </button>
          <button className="product-videos-next button">
            <svg viewBox="0 0 1024 1024" className="product-videos-icon11">
              <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
          </button>
          <button className="button product-videos-button3">
            <span>
              <span>View all cases</span>
              <br></br>
            </span>
          </button>
        </div>
      </section>
      <section className="product-videos-faq">
        <div className="product-videos-header17">
          <h2 className="product-videos-heading09">
            <span>Frequently asked </span>
            <span className="product-videos-text78">questions</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </h2>
        </div>
        <div className="product-videos-content15">
          <div className="product-videos-column">
            <div className="product-videos-element04">
              <h3 className="product-videos-header18">
                Lorem ipsum dolor sit ametetur elit?
              </h3>
              <p className="product-videos-content16">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="product-videos-element05">
              <h3 className="product-videos-header19">
                Excepteur sint occaecat cupidatat non sunt in culpa qui officia
                deserunt mollit anim id est laborum?
              </h3>
              <p className="product-videos-content17">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </p>
            </div>
            <div className="product-videos-element06">
              <h3 className="product-videos-header20">
                Tempor incididunt ut labore et dolore magna aliquat enim ad
                minim?
              </h3>
              <p className="product-videos-content18">
                Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
          <div className="product-videos-column1">
            <div className="product-videos-element07">
              <h3 className="product-videos-header21">
                Tempor incididunt ut labore et dolore magna aliquat enim ad
                minim?
              </h3>
              <p className="product-videos-content19">
                Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
            <div className="product-videos-element08">
              <h3 className="product-videos-header22">
                Lorem ipsum dolor sit ametetur elit?
              </h3>
              <p className="product-videos-content20">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="product-videos-element09">
              <h3 className="product-videos-header23">
                Incididunt ut labore et dolore?
              </h3>
              <p className="product-videos-content21">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="product-videos-footer">
        <div className="product-videos-content22">
          <div className="product-videos-main">
            <div className="product-videos-branding">
              <img
                alt="image"
                src="/logo.svg"
                className="product-videos-image4"
              />
              <span className="product-videos-text80">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </div>
            <div className="product-videos-links">
              <div className="product-videos-column2">
                <span className="product-videos-header24">Company</span>
                <div className="product-videos-list">
                  <Link to="/" className="product-videos-navlink">
                    About
                  </Link>
                  <Link to="/" className="product-videos-navlink01">
                    Services
                  </Link>
                  <Link to="/" className="product-videos-navlink02">
                    How
                  </Link>
                  <Link to="/" className="product-videos-navlink03">
                    <span className="product-videos-text81">Why</span>
                    <br></br>
                  </Link>
                </div>
              </div>
              <div className="product-videos-column3">
                <span className="product-videos-header25">Extern</span>
                <div className="product-videos-list1">
                  <Link to="/" className="product-videos-navlink04">
                    News
                  </Link>
                  <Link to="/" className="product-videos-navlink05">
                    Articles
                  </Link>
                  <Link to="/" className="product-videos-navlink06">
                    Blog
                  </Link>
                  <Link to="/" className="product-videos-navlink07">
                    Privacy
                  </Link>
                  <Link to="/" className="product-videos-navlink08">
                    Terms
                  </Link>
                  <Link to="/" className="product-videos-navlink09">
                    Legal
                  </Link>
                  <Link to="/" className="product-videos-navlink10">
                    Press
                  </Link>
                </div>
              </div>
              <div className="product-videos-column4">
                <span className="product-videos-header26">Social</span>
                <div className="product-videos-list2">
                  <Link to="/" className="product-videos-navlink11">
                    LinkedIn
                  </Link>
                  <Link to="/" className="product-videos-navlink12">
                    Twitter
                  </Link>
                  <Link to="/" className="product-videos-navlink13">
                    Instagram
                  </Link>
                  <Link to="/" className="product-videos-navlink14">
                    Facebook
                  </Link>
                  <Link to="/" className="product-videos-navlink15">
                    TikTok
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="product-videos-bottom">
            <span className="product-videos-text83">
              © 2022 togthr - All rights reserved
            </span>
            <button
              data-role="scroll-top"
              className="product-videos-button4 button"
            >
              <img
                alt="image"
                src="/arrow.svg"
                className="product-videos-image5"
              />
            </button>
          </div>
        </div>
      </div>
      <div>
        <Script
          html={`<script>
document.addEventListener("DOMContentLoaded", function() {
  // get the element with the "scroll-top" data-role
  const scrollTopBtn = document.querySelector('[data-role="scroll-top"]');

  // when the element is clicked
  scrollTopBtn.addEventListener("click", function() {
    // smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
});
</script>
`}
        ></Script>
      </div>
    </div>
  )
}

export default ProductVideos
