import React from 'react'
import { Link } from 'react-router-dom'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Check from '../components/check'
import Line from '../components/line'
import IconContainer from '../components/icon-container'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>PersonaAI</title>
        <meta property="og:title" content="PersonaAI" />
      </Helmet>
      <section className="home-hero">
        <video
          src="https://aipersona.info/aipersona_video1.mp4"
          loop
          muted
          poster="/hero-bg.png"
          preload="auto"
          autoPlay
          playsInline
          className="home-video"
        ></video>
        <header data-thq="thq-navbar" className="home-navbar">
          <img
            alt="image"
            src="https://aipersona.info/personaai_logo.png"
            className="home-branding"
          />
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <div className="home-hamburger">
              <img alt="image" src="/hamburger.svg" className="home-icon" />
            </div>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav"
            >
              <div className="home-container01">
                <img alt="image" src="/logo.svg" className="home-image" />
                <div data-thq="thq-close-menu" className="home-menu-close">
                  <svg viewBox="0 0 1024 1024" className="home-icon01">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-nav1"
              >
                <span className="home-text">About</span>
                <span className="home-text01">Features</span>
                <span className="home-text02">Pricing</span>
                <span className="home-text03">Team</span>
                <span className="home-text04">Blog</span>
              </nav>
              <div className="home-container02">
                <button className="home-login button">Login</button>
                <button className="button">Register</button>
              </div>
            </div>
            <div className="home-icon-group">
              <svg viewBox="0 0 950.8571428571428 1024" className="home-icon03">
                <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
              </svg>
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon05">
                <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
              </svg>
              <svg viewBox="0 0 602.2582857142856 1024" className="home-icon07">
                <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
              </svg>
            </div>
          </div>
        </header>
        <div className="home-hero-content">
          <div className="home-header-container">
            <div className="home-header">
              <h1 className="home-heading">
                <span className="home-text05">
                  PersonaAI - Powering the Future of 3D
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text06">Character Interaction</span>
                <br></br>
              </h1>
            </div>
            <p className="home-caption">
              Leverage the latest advancements in digital characters authoring
              frameworks, AI and physics to interact with hyper-realistic 3D
              characters in your Desktop, VR, or AR environment.
            </p>
          </div>
        </div>
      </section>
      <section className="home-note">
        <h2 className="home-caption01">
          The most comprehensive lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna.
        </h2>
      </section>
      <section className="home-statistics">
        <div className="home-content">
          <div className="home-stat">
            <h3 className="home-header01">$1.69M</h3>
            <span className="home-caption02">
              Consectetur adipiscing
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </div>
          <div className="home-stat1">
            <h3 className="home-header02">Multiverse</h3>
            <span className="home-caption03">Unde omnis iste</span>
          </div>
          <div className="home-stat2">
            <h3 className="home-header03">500K</h3>
            <span className="home-caption04">Accusantium doloremque</span>
          </div>
        </div>
      </section>
      <section className="home-slider">
        <div className="home-header04">
          <h2 className="home-heading01">
            Leading the Virtual Frontier with PersonaAI
          </h2>
        </div>
        <div className="home-selector">
          <p className="home-caption05">
            <span className="home-text08">
              PersonaAI is a cutting-edge platform, founded in 2019 and
              developed with passion by a team of seasoned developers and
              mathematicians. We harness the power of AI, advanced physics, and
              a flexible character creation framework to bring 3D character
              simulation to life in an immersive and interactive environment.
            </span>
            <br className="home-text09"></br>
            <br className="home-text10"></br>
            <span className="home-text11">
              Through our proprietary real-time C++ physics engine, we&apos;ve
              developed an unmatched capability for realistic simulations of
              cloth and human body soft tissues. This unique technology offers
              powerful real-time simulation - an offering that sets us apart in
              the market.
            </span>
          </p>
        </div>
      </section>
      <div className="home-container03"></div>
      <div className="home-gallery">
        <h1 className="home-text12">
          <span>
            Realtime
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="home-text14">
            Rendering
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
        </h1>
      </div>
      <div className="home-gallery1">
        <div className="home-container04">
          <div className="home-container05">
            <h1 className="home-heading02">Rendering Sample #1</h1>
            <span className="home-text15">Lorem ipsum dolor sit amet</span>
          </div>
        </div>
        <div className="home-container06">
          <div className="home-container07">
            <h1 className="home-text16">High quality rendering #2</h1>
            <span className="home-text17">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
        <div className="home-container08">
          <div className="home-container09">
            <h1 className="home-text19">Project Title</h1>
            <span className="home-text20">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
        <div className="home-container10">
          <div className="home-container11">
            <h1 className="home-text22">Project Title</h1>
            <span className="home-text23">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
        <div className="home-container12">
          <div className="home-container13">
            <h1 className="home-text25">Project Title</h1>
            <span className="home-text26">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
        <div className="home-container14">
          <div className="home-container15">
            <h1 className="home-text28">Project Title</h1>
            <span className="home-text29">
              <span>Lorem ipsum dolor sit amet</span>
            </span>
          </div>
        </div>
      </div>
      <section className="home-get-started">
        <div className="home-header-container1">
          <div className="home-header05">
            <h2 className="home-heading03">
              Innovation and Immersion at Customers Fingertips
            </h2>
            <p className="home-caption06">
              <span className="home-text31">
                Our platform empowers users to create, share, and interact with
                lifelike 3D characters. With its highly customizable characters
                and environments, it&apos;s the ideal platform for buying and
                selling user-generated content through a marketplace.
              </span>
              <br className="home-text32"></br>
              <br className="home-text33"></br>
              <span className="home-text34">
                Additionally, PersonaAI integrates with AI LLM models to bring
                characters to life, making them more interactive, dynamic, and
                alive than ever before. We make every interaction feel real,
                personal, and vibrant.
              </span>
            </p>
          </div>
          <div className="home-button"></div>
        </div>
      </section>
      <section className="home-objectives">
        <div className="home-content01">
          <span className="home-text35">Objectives</span>
          <div className="home-objectives-list">
            <div className="objective">
              <h3 className="home-text36">Growth</h3>
              <p className="home-text37">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="objective home-objective1">
              <h3 className="home-text38">Develop</h3>
              <p className="home-text39">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="objective home-objective2">
              <h3 className="home-text40">Optimize</h3>
              <p className="home-text41">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="home-growth">
        <div className="home-content02">
          <div className="home-header06">
            <div className="home-header07">
              <h2 className="home-heading04">
                <span>The Future Is </span>
                <span className="home-text43">AI/AR/VR</span>
                <br></br>
              </h2>
            </div>
            <p className="home-caption07">
              <span>
                With opportunities for release on popular platforms like Steam,
                Epic Games Store, Oculus Store, and Apple&apos;s Reality Pro App
                Store, we&apos;re primed for expansion.
              </span>
              <br></br>
              <br></br>
              <span>
                {' '}
                Additional growth avenues include adapting our product for
                online platforms and marketplaces, licensing our physics engine
                to other companies, and further developing our AI chatbots to
                stay at the forefront of AI-powered interaction.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </p>
          </div>
        </div>
        <div className="home-images">
          <div className="home-square"></div>
          <img
            alt="image"
            src="https://aipersona.info/image854.jpg"
            className="home-image1"
          />
        </div>
      </section>
      <section className="home-experience">
        <div className="home-images1">
          <div className="home-square1"></div>
          <img
            alt="image"
            src="/experience-1400w.png"
            className="home-image2"
          />
        </div>
        <div className="home-content03">
          <div className="home-header08">
            <div className="home-header-container2">
              <div className="home-header09">
                <h2 className="home-heading05">
                  <span>A worlds class </span>
                  <span className="home-text50">experience</span>
                </h2>
              </div>
              <p className="home-caption08">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do
                eiusmod.
              </p>
            </div>
            <div className="home-checkmarks">
              <div className="home-check">
                <div className="home-mark">
                  <svg viewBox="0 0 1024 1024" className="home-icon09">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text51">
                  Duis aute irure dolor in reprehenderit
                </span>
              </div>
              <div className="home-check1">
                <div className="home-mark1">
                  <svg viewBox="0 0 1024 1024" className="home-icon11">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text52">Lorem ipsum dolor sit amet</span>
              </div>
              <div className="home-check2">
                <div className="home-mark2">
                  <svg viewBox="0 0 1024 1024" className="home-icon13">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text53">
                  Voluptate velit esse cillum dolore eu fugiat nulla
                </span>
              </div>
              <div className="home-check3">
                <div className="home-mark3">
                  <svg viewBox="0 0 1024 1024" className="home-icon15">
                    <path d="M823.168 225.835l-439.168 439.168-183.168-183.168c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l213.333 213.333c16.683 16.683 43.691 16.683 60.331 0l469.333-469.333c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0z"></path>
                  </svg>
                </div>
                <span className="home-text54">
                  Excepteur sint occaecat cupidatat non proident sunt in culpa
                </span>
              </div>
            </div>
          </div>
          <div className="home-testimonial">
            <div className="home-content04">
              <span className="home-text55">Testimonial</span>
              <p className="home-text56">
                “Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.”
              </p>
            </div>
            <div className="home-information">
              <div className="home-author">
                <img
                  alt="image"
                  src="https://images.unsplash.com/photo-1618151313441-bc79b11e5090?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDEzfHxwb3RyYWl0fGVufDB8fHx8MTY3MTA1NjMzMw&amp;ixlib=rb-4.0.3&amp;w=200"
                  className="home-icon17"
                />
                <span className="home-name">Cory Smith</span>
              </div>
              <img alt="image" src="/logoipsum-2.svg" className="home-from" />
            </div>
          </div>
        </div>
      </section>
      <section className="home-create">
        <div className="home-content05">
          <div className="home-header10">
            <h2 className="home-heading06">
              <span>
                PersonaAI in
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text58">Action</span>
              <br></br>
            </h2>
          </div>
          <div className="home-container16">
            <span className="home-text60">
              <br className="home-text61"></br>
              <br></br>
            </span>
            <span className="home-text63">
              Get a taste of the PersonaAI experience through our early demo
              prototype, Mirage, available on Steam, or watch more of our
              product videos below.
            </span>
          </div>
          <div>
            <Script
              html={`<iframe src="https://store.steampowered.com/widget/1245780/" frameborder="0" width="646" height="190"></iframe>`}
            ></Script>
          </div>
          <button className="home-button1 button">
            <a
              href="https://morganavr.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link"
            >
              Watch Product Videos
            </a>
          </button>
        </div>
      </section>
      <section className="home-prototype-videos">
        <div className="home-header11">
          <h2 className="home-heading07"> Early Prototype Product Videos</h2>
        </div>
        <div className="home-selector1">
          <p className="home-caption09">
            Early prototype of PersonaAI was codenamed &quot;Mirage&quot; and is
            released as an adult-oriented game, a strategic move designed to
            accelerate fund acquisition towards development of the PersonaAI
            platform.
          </p>
          <p className="home-caption10">
            To watch &quot;Launch Trailer&quot; below please type this password
            when prompted: mirage31337
          </p>
        </div>
        <div className="home-div1">
          <Script
            html={`<div class="video">
				<h3>Mirage - Launch Trailer:  (password is mirage31337)</h3>		
				<iframe src="https://player.vimeo.com/video/438821129" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>				
			</div>

			<div class="video">
				<h3>Review of Mirage by Rob Cram:</h3>
				<iframe width="560" height="315" src="https://www.youtube.com/embed/6VIPdUgavPs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			</div>

			<div class="video">
				<h3>Animations:</h3>
				<iframe src="https://player.vimeo.com/video/439196364" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>

			<div class="video">
				<h3>Posing:</h3>
				<iframe src="https://player.vimeo.com/video/437457708" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>

			<div class="video">
				<h3>First girl:</h3>
				<iframe src="https://player.vimeo.com/video/437473241" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>

			<div class="video">
				<h3>Second girl:</h3>
				<iframe src="https://player.vimeo.com/video/437472836" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>			
			</div>

			<div class="video">
				<h3>Third girl:</h3>
				<iframe src="https://player.vimeo.com/video/437472432" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>
			
			<div class="video">
				<h3>Morphs:</h3>
				<iframe src="https://player.vimeo.com/video/437470830" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>

			<div class="video">
				<h3>Mirage Creative:</h3>
				<iframe src="https://player.vimeo.com/video/784617952" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
			</div>		`}
          ></Script>
        </div>
      </section>
      <section className="home-comparision">
        <div className="home-header-container3">
          <div className="home-header12">
            <h2 className="home-heading08">
              Be Part of the Future with PersonaAI
            </h2>
          </div>
          <p className="home-caption11">
            We invite you to invest in PersonaAI, a platform that&apos;s set to
            transform virtual character interaction and simulation. With a
            successful track record and substantial growth opportunities, seize
            this opportunity to be at the forefront of high-growth industries.
          </p>
        </div>
        <div className="home-table">
          <div className="home-row">
            <div className="home-headers">
              <span className="home-text64">Placeholder</span>
            </div>
            <div className="home-plans">
              <div className="home-row01">
                <span className="home-text65">TOGHTR</span>
                <span className="home-text66">AGENCY</span>
                <span className="home-text67">FRELLANCE</span>
              </div>
            </div>
          </div>
          <div className="home-row02">
            <div className="home-headers1">
              <span className="home-text68">
                Duis aute irure dolor in reprehenderit
              </span>
            </div>
            <div className="home-plans1">
              <div className="home-row03">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row04">
            <div className="home-headers2">
              <span className="home-text69">Lorem ipsum dolor sit amet</span>
            </div>
            <div className="home-plans2">
              <div className="home-row05">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row06">
            <div className="home-headers3">
              <span className="home-text70">
                Duis aute irure dolor in reprehenderit
              </span>
            </div>
            <div className="home-plans3">
              <div className="home-row07">
                <div className="value">
                  <span className="home-text71">Custom</span>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row08">
            <div className="home-headers4">
              <span className="home-text72">
                Duis aute irure dolor in reprehenderit
              </span>
            </div>
            <div className="home-plans4">
              <div className="home-row09">
                <div className="value">
                  <span className="home-text73">Unlimited</span>
                </div>
                <div className="value">
                  <span className="home-text74">Limited</span>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row10">
            <div className="home-headers5">
              <span className="home-text75">Lorem ipsum dolor sit amet</span>
            </div>
            <div className="home-plans5">
              <div className="home-row11">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
                <div className="value">
                  <span className="home-text76">Limited</span>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row12">
            <div className="home-headers6">
              <span className="home-text77">Lorem ipsum dolor sit amet</span>
            </div>
            <div className="home-plans6">
              <div className="home-row13">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <Check></Check>
                </div>
              </div>
            </div>
          </div>
          <div className="home-row14">
            <div className="home-headers7">
              <span className="home-text78">Lorem ipsum dolor sit amet</span>
            </div>
            <div className="home-plans7">
              <div className="home-row15">
                <div className="value">
                  <Check></Check>
                </div>
                <div className="value">
                  <span className="home-text79">Limited</span>
                </div>
                <div className="value">
                  <Line></Line>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="button home-button2">
          <a href="mailto:info@chisei.tech?subject=" className="home-link1">
            Contact Email: info@chisei.tech
          </a>
        </button>
      </section>
      <section className="home-data">
        <div className="home-header-container4">
          <div className="home-header13">
            <div className="home-row16">
              <h2 className="home-heading09">
                <span>
                  Understand your data to manage all in one
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="home-text81">platform</span>
                <br></br>
              </h2>
            </div>
          </div>
          <p className="home-caption12">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </p>
        </div>
        <div className="home-content06">
          <div className="home-image3">
            <img alt="image" src="/data-1400w.png" className="home-image4" />
          </div>
          <div className="home-accordion">
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header14">Management</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header15">Integrations</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name1"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header16">Customer Solutions</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name2"></IconContainer>
            </div>
            <div data-role="accordion-container" className="accordion">
              <div>
                <h3 className="home-header17">Recruiting</h3>
                <p data-role="accordion-content" className="accordion-content">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </p>
              </div>
              <IconContainer rootClassName="icon-container-root-class-name3"></IconContainer>
            </div>
            <div>
              <Script
                html={`<script>
 /*
  Accordion - Code Embed
  */
  const accordionContainers = document.querySelectorAll('[data-role="accordion-container"]'); // All accordion containers
  const accordionContents = document.querySelectorAll('[data-role="accordion-content"]'); // All accordion content
  const accordionIconsClosed = document.querySelectorAll('[data-role="accordion-icon-closed"]'); // All accordion closed icons
  const accordionIconsOpen = document.querySelectorAll('[data-role="accordion-icon-open"]'); // All accordion open icons

  accordionContents.forEach((accordionContent) => {
      accordionContent.style.display = "none"; //Hides all accordion contents
  });

  accordionIconsClosed.forEach((icon) => {
    icon.style.display = "flex"
  })

  accordionIconsOpen.forEach((icon) => {
    icon.style.display = "none"
  })

  accordionContainers.forEach((accordionContainer, index) => {
      accordionContainer.addEventListener("click", () => {
          if (accordionContents[index].style.display === "flex") {
              // If the accordion is already open, close it
              accordionContents[index].style.display = "none";
              accordionIconsClosed[index].style.display = "flex";
              accordionIconsOpen[index].style.display = "none"
          } else {
              // If the accordion is closed, open it
              accordionContents.forEach((accordionContent) => {
                  accordionContent.style.display = "none"; //Hides all accordion contents
              });

              accordionIconsClosed.forEach((accordionIcon) => {
                  accordionIcon.style.display = "flex"; // Resets all icon transforms to 0deg (default)
              });

              accordionIconsOpen.forEach((accordionIcon) => {
                accordionIcon.style.display = "none";
              })
              
              accordionContents[index].style.display = "flex"; // Shows accordion content
              accordionIconsClosed[index].style.display = "none"; // Rotates accordion icon 180deg
              accordionIconsOpen[index].style.display = "flex";
          }
      });
  });
</script>
`}
              ></Script>
            </div>
          </div>
        </div>
      </section>
      <section className="home-customer">
        <div className="home-header18">
          <h2 className="home-heading10">
            <span>
              Customer validation is an essential phase of the
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text84">product development process</span>
            <br></br>
          </h2>
        </div>
        <div className="home-quotes">
          <div className="home-quote">
            <p className="home-quote1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
            <div className="home-author1">
              <span className="home-name1">Joanna Smith</span>
              <span className="home-location">Briville</span>
            </div>
          </div>
          <div className="home-quote2">
            <p className="home-quote3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
            <div className="home-author2">
              <span className="home-name2">Joanna Smith</span>
              <span className="home-location1">Briville</span>
            </div>
          </div>
          <div className="home-quote4">
            <p className="home-quote5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
            <div className="home-author3">
              <span className="home-name3">Joanna Smith</span>
              <span className="home-location2">Briville</span>
            </div>
          </div>
          <div className="home-quote6">
            <p className="home-quote7">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </p>
            <div className="home-author4">
              <span className="home-name4">Joanna Smith</span>
              <span className="home-location3">Briville</span>
            </div>
          </div>
        </div>
        <div className="home-controls">
          <button className="home-previous button">
            <svg viewBox="0 0 1024 1024" className="home-icon18">
              <path d="M542.165 780.501l-225.835-225.835h494.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-494.336l225.835-225.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-298.667 298.667c-4.096 4.096-7.168 8.789-9.259 13.824-2.176 5.205-3.243 10.795-3.243 16.341 0 10.923 4.181 21.845 12.501 30.165l298.667 298.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331z"></path>
            </svg>
          </button>
          <button className="home-next button">
            <svg viewBox="0 0 1024 1024" className="home-icon20">
              <path d="M481.835 243.499l225.835 225.835h-494.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h494.336l-225.835 225.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l298.667-298.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-298.667-298.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331z"></path>
            </svg>
          </button>
          <button className="button home-button3">
            <span>
              <span>View all cases</span>
              <br></br>
            </span>
          </button>
        </div>
      </section>
      <section className="home-faq">
        <div className="home-header19">
          <h2 className="home-heading11">
            <span>Frequently asked </span>
            <span className="home-text90">questions</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
          </h2>
        </div>
        <div className="home-content15">
          <div className="home-column">
            <div className="home-element04">
              <h3 className="home-header20">
                Lorem ipsum dolor sit ametetur elit?
              </h3>
              <p className="home-content16">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="home-element05">
              <h3 className="home-header21">
                Excepteur sint occaecat cupidatat non sunt in culpa qui officia
                deserunt mollit anim id est laborum?
              </h3>
              <p className="home-content17">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo.
              </p>
            </div>
            <div className="home-element06">
              <h3 className="home-header22">
                Tempor incididunt ut labore et dolore magna aliquat enim ad
                minim?
              </h3>
              <p className="home-content18">
                Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
          </div>
          <div className="home-column1">
            <div className="home-element07">
              <h3 className="home-header23">
                Tempor incididunt ut labore et dolore magna aliquat enim ad
                minim?
              </h3>
              <p className="home-content19">
                Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
              </p>
            </div>
            <div className="home-element08">
              <h3 className="home-header24">
                Lorem ipsum dolor sit ametetur elit?
              </h3>
              <p className="home-content20">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
            <div className="home-element09">
              <h3 className="home-header25">Incididunt ut labore et dolore?</h3>
              <p className="home-content21">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="home-footer">
        <div className="home-content22">
          <div className="home-main">
            <div className="home-branding1">
              <img
                alt="image"
                src="https://aipersona.info/personaai_logo.png"
                className="home-image5"
              />
              <span className="home-text92">
                Unlocking the potential of immersive digital character
                interactions using AI and advanced physics simulations.
              </span>
            </div>
            <div className="home-links">
              <div className="home-column2">
                <span className="home-header26">Company</span>
                <div className="home-list">
                  <a
                    href="https://chisei.tech/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="home-link2"
                  >
                    Chisei Technologies Sp. z o.o.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </a>
                  <a
                    href="mailto:info@chisei.tech?subject="
                    className="home-link3"
                  >
                    info@chisei.tech
                  </a>
                </div>
              </div>
              <div className="home-column3">
                <span className="home-header27">Extern</span>
                <div className="home-list1">
                  <Link to="/" className="home-navlink">
                    News
                  </Link>
                  <Link to="/" className="home-navlink01">
                    Articles
                  </Link>
                  <Link to="/" className="home-navlink02">
                    Blog
                  </Link>
                  <Link to="/" className="home-navlink03">
                    Privacy
                  </Link>
                  <Link to="/" className="home-navlink04">
                    Terms
                  </Link>
                  <Link to="/" className="home-navlink05">
                    Legal
                  </Link>
                  <Link to="/" className="home-navlink06">
                    Press
                  </Link>
                </div>
              </div>
              <div className="home-column4">
                <span className="home-header28">Social</span>
                <div className="home-list2">
                  <Link to="/" className="home-navlink07">
                    LinkedIn
                  </Link>
                  <Link to="/" className="home-navlink08">
                    Twitter
                  </Link>
                  <Link to="/" className="home-navlink09">
                    Instagram
                  </Link>
                  <Link to="/" className="home-navlink10">
                    Facebook
                  </Link>
                  <Link to="/" className="home-navlink11">
                    TikTok
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="home-bottom">
            <span className="home-text93">
              © 2019-2023 PersonaAI - All rights reserved
            </span>
            <button data-role="scroll-top" className="home-button4 button">
              <img alt="image" src="/arrow.svg" className="home-image6" />
            </button>
          </div>
        </div>
      </div>
      <div>
        <Script
          html={`<script>
document.addEventListener("DOMContentLoaded", function() {
  // get the element with the "scroll-top" data-role
  const scrollTopBtn = document.querySelector('[data-role="scroll-top"]');

  // when the element is clicked
  scrollTopBtn.addEventListener("click", function() {
    // smoothly scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
});
</script>
`}
        ></Script>
      </div>
    </div>
  )
}

export default Home
